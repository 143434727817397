import fetch from "../utils/fetch";
//获取组织架构
export function getTreeData(query) {
  return fetch({
    url: "/secOrganize/tree",
    method: "get",
    params: query,
  });
}

// 新增
export function addTreeData(query) {
  return fetch({
    url: "/secOrganize/save",
    method: "post",
    data: query,
  });
}

// 删除
export function delTreeData(id) {
  return fetch({
    url: `/secOrganize/${id}/del`,
    method: "delete",
  });
}

export function getAllcounrtyData(query) {
  return fetch({
    url: "/internationalData/countryEpidemicCount",
    method: "get",
    params: query,
  });
}

export function editTreeData(id, query) {
  return fetch({
    url: `/secOrganize/${id}/update`,
    method: "PUT",
    data: query,
  });
}
