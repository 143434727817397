<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="dialogVisible = true;options = 'create'"
        >新增机构/部门</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      :tree-props="{ children: 'secOrganizes', hasChildren: 'hasChildren' }"
      :row-class-name="rowClassName"
    >
      <!-- <el-table-column prop="date" label="日期" sortable width="180">
      </el-table-column> -->
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="resident" label="驻地"> </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="add(scope.row)"
            v-if="~~scope.row.type >= 0 && ~~scope.row.type < 2"
            size="small"
            plain
            >新增</el-button
          >
          <el-button type="warn" size="small" plain @click="edit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="small"
            plain
            @click="deleteData(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="60px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="驻地" prop="resident">
          <el-select
            v-model="form.resident"
            placeholder="请选择"
            size="small"
            style="width: 100%"
            filterable
          >
            <el-option
              :label="item.name"
              :value="item.name"
              v-for="item in countries"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import {
  getTreeData,
  delTreeData,
  addTreeData,
  getAllcounrtyData,
  editTreeData,
} from "@/api/organization";
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      form: {
        type: 0,
        name:'',
        resident:''
      },
      countries: [{ name: "中国", value: "" }],
      rules: {
        resident: [{ required: true, message: "请选择驻地", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      options: "create",
      id:''
    };
  },
  mounted() {
    this.getData();
    this.getCountry();
  },
  methods: {
    async getData() {
      let res = await getTreeData();
      this.tableData = res.data.data;
      console.log(this.tableData);
    },
    async deleteData(id) {
      this.$confirm("确认删除", "提示").then(async (res) => {
        let result = await delTreeData(id);
        this.$message.success("删除成功");
        this.getData();
      });
    },
    async addData() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.options === "create") {
            let res = await addTreeData(this.form);
            this.$message.success("添加成功");
          } else {
            delete this.form.type
            let params = {...this.form}
            for(let k in params){
              if(params[k] === this.judge[k]) params[k] = null
            }
            let res = await editTreeData(this.id, params);
            this.$message.success("修改成功");
          }
          this.getData();
          this.dialogVisible = false;
          this.form = { type: 0, resident: "", name: "" };
          this.id=""
          this.judge = null
          this.$store.dispatch('refresh')
        } else {
          return false;
        }
      });
    },
    async getCountry() {
      let res = await getAllcounrtyData();
      this.countries = this.countries.concat(res.data.data);
    },
    add(node) {
      this.options = "create";
      this.form.parentId = node.id;
      this.form.type = node.type + 1;
      this.dialogVisible = true;
    },
    rowClassName({ row }) {
      if (row.type == 2) {
        return "second-row";
      } else if (row.type == 1) {
        return "second-row";
      } else {
        return "first-row";
      }
    },
    edit(obj) {
      this.judge = obj
      this.dialogVisible = true;
      this.form.name = obj.name;
      this.form.resident = obj.resident
      this.id = obj.id
      this.options = "edit";
    },
  },
};
</script>

<style>
/* .third-row {
  background: #fff;
}
.second-row {
  background: #ddebfd !important;
} */
/* .first-row {
  background: #cbe1fc !important;
} */
</style>